'use client';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';

import Image from 'next/legacy/image';

import { FaBars } from 'react-icons/fa';
import { GiSplitCross } from 'react-icons/gi';
const Header = () => {
	const [navfix, setNavfix] = useState(false);
	const [navfold, setNavfold] = useState(false);

	useEffect(() => {
		const setFixed = () => {
			if (window.scrollY) {
				setNavfix(true);
			} else {
				setNavfix(false);
			}
		};
		window.addEventListener('scroll', setFixed);
	}, []);

	const navItem = [
		{
			link: '/',
			title: 'Home',
		},
		{
			link: '/post',
			title: 'Post',
		},
		{
			link: '/',
			title: 'Features',
		},
		{
			link: '/contact',
			title: 'Contact us',
		},
		{
			link: '/',
			title: 'About',
		},

		{
			link: '/login',
			title: 'Login / Registration',
		},
	];

	return (
		<div className={`${navfix ? 'header_area active_nav_fixed animated slideTopDown' : 'header_area'}`}>
			<div className="container">
				<div className="header_wrapper">
					{/* <div className="header_logo">
						<Link href="/" passHref legacyBehavior>
							<Image src={'/images/brand-logo.png'} alt="Picturer" layout="fill" />
						</Link>
					</div> */}
					<h1 style={{ margin: 0 }}>Logo</h1>
					<div className="header_navbar_link">
						<ul className="nav-menu-ul">
							{navItem.map((item, index) => (
								<li key={index}>
									<a href={item.link}>{item.title}</a>
								</li>
							))}
						</ul>
					</div>

					<div className="responsive_nav_wrapper">
						<div
							className="nav_icon"
							onClick={() => {
								setNavfold(!navfold);
							}}
						>
							{!navfold ? <FaBars size={24} /> : <GiSplitCross size={24} />}
						</div>

						<div className={`${navfold ? 'nav_menu_wrapper nav_menu_wrapper_fold' : 'nav_menu_wrapper'}`}>
							<ul className="nav_menu">
								{navItem.map((item, index) => (
									<li className="nav_menu_item" key={index}>
										<a href={item.link}>{item.title}</a>
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Header;
